import { ref, shallowRef } from "vue"
import { promiseTimeout } from '@vueuse/core';


const showModal = ref(false)
const closePrevent = ref(false)
const modalComponent = shallowRef<any>(null)
const modalProps = ref<any>(null)
const animationClass = ref('');
const customClass = ref('')

const errorAnimation = async (className: string, duration: number = 250) => {
    animationClass.value = className
    await promiseTimeout(duration);
    animationClass.value = ""
}

export const useModal = () => {

    const openModal = (component: any, prevent?: boolean, props?: any) => {
        closePrevent.value = prevent ? true : false
        if (props) {
            modalProps.value = props
        }
        modalComponent.value = component
        showModal.value = true
    }
    
    const closeModal = () => {
        showModal.value = false
        modalComponent.value = null
    }
    
    return {
        showModal,
        modalComponent,
        closePrevent,
        customClass,
        openModal,
        closeModal,
        animationClass,
        errorAnimation,
    }
}