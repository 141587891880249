import ApiDataSerivice from "@/lib/apiDataService"
import dayjs from "dayjs"
import { useSessionStorage, promiseTimeout } from "@vueuse/core"
import { Canvas } from "@/lib/canvas"
import { ref } from "vue"

const refreshView = ref(false)
const pendingRefresh = ref(false)
const CommonPapel: any = useSessionStorage("CommonPapel", {})
const FotograficosRollos = useSessionStorage("Fotograficos_Rollos", [] as any)
const FineArtRollos = useSessionStorage("FineArt_Rollos", [] as any)
const CommonsCanvas = useSessionStorage("Canvas_Common", {
  listRollos: [] as Rollo[],
  AcabadosCanto: [] as AcabadoCanto[],
  Bastidores: [] as Bastidor[],
  Barnices: [] as Barniz[],
  rawCantos: [] as Canto[],
  Maderas: [] as Madera[],
  tab: "",
} as isCommonsCanvas)

const lastCommon: any = useSessionStorage("lastCommon", "")
const lastRPHupdate: any = useSessionStorage("lastRPHupdate", "")
const lastRFAupdate: any = useSessionStorage("lastRFAupdate", "")
const lastCanvasUpdate = useSessionStorage("lastCanvas", "")
const StockSelection = useSessionStorage("Stock_Canvas_Selection", {} as isStockCanvasSelection)
const CustomSelection = useSessionStorage("Custom_Canvas_Selection", {} as isCustomCanvasSelection)

const setCommonsCanvas = (common: commonCanvasResponse) => {
  const commons = {
    listRollos: common.Linea[0] ? common.Linea[0].Rollos : [],
    AcabadosCanto: common.AcabadoCanto,
    Barnices: common.Barnices,
    rawCantos: common.Canto,
    Bastidores: common.inventarioBastidores,
    Maderas: common.MaterialBastidor,
    tab: "",
  }
  return commons
}

export const quickUnmount = () => {
  const qUnmount = async(timeout: number = 300) => {
    refreshView.value = true
    await promiseTimeout(timeout)
    refreshView.value = false
    pendingRefresh.value = false
  }
  return { qUnmount, refreshView, pendingRefresh }
}

export async function fullCall() {
  try {
    const resp = await ApiDataSerivice.parallelGet([
      "data/papel/",
      "data/fineart/",
      "data/fotograficos/",
      "data/canvas/",
      "cotizador/defaults/canvas/",
    ])
    CommonPapel.value = {
      Laminados: resp["data/papel/"].Laminados,
      Spray: resp["data/papel/"].Spray,
      Montajes: resp["data/papel/"].Montajes,
    }
    FotograficosRollos.value = resp["data/fotograficos/"][0].Rollos
    FineArtRollos.value = resp["data/fineart/"].Linea
    CommonsCanvas.value = setCommonsCanvas(resp["data/canvas/"])
    StockSelection.value = Canvas.setStockDefault(resp["cotizador/defaults/canvas/"][0])
    CustomSelection.value = Canvas.setCustomDefault(resp["cotizador/defaults/canvas/"][0])

    lastCanvasUpdate.value = dayjs().toString()
    lastCommon.value = dayjs().toString()
    lastRPHupdate.value = dayjs().toString()
    lastRFAupdate.value = dayjs().toString()
  } catch (error) {
    console.log("Se ha producido un error", error)
  }
}

export function getCookie(name: string): string | null {
  const cookieArray: string[] = document.cookie.split(";") // Split the cookie string into an array
  for (const cookie of cookieArray) {
    const cookiePair: string[] = cookie.split("=") // Split each key-value pair
    if (name === cookiePair[0]?.trim()) {
      return decodeURIComponent(cookiePair[1] as string) // Return the value
    }
  }
  return null // Return null if the cookie is not found
}
