import { defineStore } from "pinia"
import { ref, computed, shallowRef } from "vue"
import { useWindowSize } from "@vueuse/core"
import { useLocalStorage, useSessionStorage } from "@vueuse/core"

export const useCotizadorData = defineStore("getData", () => {
  const { width } = useWindowSize()
  const needRefresh = ref(false)
  const promos = ref({})
  const sv = computed(() => (width.value < 650 ? true : false))
  const maxScreen = computed(() => (width.value < 920 ? true : false))
  const loading = ref<boolean>(false)
  const guestEdit = useSessionStorage("guestEdit", false)
  const isPublic = useSessionStorage("isPublic", false)
  const codigoPostal = useSessionStorage("codigoPostal", "")
  const newClient = useSessionStorage("isNewClient", false)
  const publicOrderUUID = useSessionStorage("publicOrderUUID", "")
  const Globals = ref(useSessionStorage("Globals", {} as any))
  const component2Mount = shallowRef<any>()
  const ModalSettings = ref({} as isModalSettings)
  const showModal = ref(false)
  const Usr: any = ref(useLocalStorage("userData", {}))
  const Tkns: any = ref(useLocalStorage("tokens_expire", {}))
  const acknowledge = ref(useLocalStorage("acknowledge", '')) // se cambia a true si se desea desactivar
  const loadingGlobal = ref(useSessionStorage("isLoading", false))
  const errorPage = ref<boolean | string>(false)

  // moving all the data to the store for better performance
  const CommonPapel: any = ref(useSessionStorage("CommonPapel", {}))
  const lastCommon: any = ref(useSessionStorage("lastCommon", ""))

  const restore = () => {
    needRefresh.value = false
    promos.value = {}
    component2Mount.value = null
    ModalSettings.value = {} as isModalSettings
    Usr.value = {}
    Tkns.value = {}
    CommonPapel.value = {}
    lastCommon.value = ""
  }

  return {
    Globals,
    loading,
    promos,
    needRefresh,
    acknowledge,
    sv,
    maxScreen,
    component2Mount,
    ModalSettings,
    showModal,
    Usr,
    Tkns,
    loadingGlobal,
    CommonPapel,
    lastCommon,
    guestEdit,
    isPublic,
    newClient,
    errorPage,
    publicOrderUUID,
    codigoPostal,
    restore,
  }
})

export default useCotizadorData
