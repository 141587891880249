<template>
  <div class="body-background relative" :class="showModal ? 'blur-md' : ''"></div>
  
  <div class="w-full max-w-6xl mx-auto p-2 sm:px-4 sm:pb-4 pt-2">
    
    <base-modal />
    <base-screenshot />
    <anuncios />

    <div id="site" :class="showModal ? 'blur-md' : ''" class="relative">
      <header-nav v-if="Globals.Logo && !isLoading" :logo="Globals.Logo" @logout="logout()"/>
      <div class="bg-white dark:bg-zinc-800 border-2 border-t-0 border-neutral-300 dark:border-neutral-600 rounded-b-2xl z-10">
        <div v-if="loadingGlobal">
          <loading-spin />
        </div>
        <div v-else>
          <RouterView v-slot="{ Component }">
            <transition 
              enter-active-class="transition-all duration-500 ease-out"
              leave-active-class="transition-all duration-300 ease-slide-fade"
              enter-from-class="transform translate-x-10 opacity-0"
              leave-to-class="transform translate-x-10 opacity-0"
            >
              <component :is="Component" v-if="!loadingGlobal && !refreshView"/>
            </transition>
          </RouterView>
           <p class="text-sm mb-3 mt-6 text-neutral-700 dark:text-neutral-200 text-center tracking-wider">Todos los precios incluyen I.V.A</p>
        </div>
      </div>

      <div v-if="Usr.access_token_expiration !== null">
        <div v-if="Usr.is_superuser && router.currentRoute.value.name === 'cart'" class="w-full max-w-6xl mx-auto p2 sm:pb-4 pt-2 mt-3 bg-white dark:bg-zinc-800 border-neutral-300 dark:border-neutral-600 border-2 rounded-2xl">
            <pos-panel />
        </div>
      </div>

      <Footer />

    </div>
  </div>


</template>

<script lang="ts" setup>
import { onBeforeMount, defineAsyncComponent, watch, onBeforeUnmount, onMounted } from "vue"
import { storeToRefs } from "pinia"
import { RouterView, useRouter } from "vue-router"
import { useCart } from "@/store/cartStore"
import { useCotizadorData } from "./store/CotizadorStore"
import { promiseTimeout, useSessionStorage } from "@vueuse/core"
import { homeCall } from "./lib/dataFetch"
import { fullCall, getCookie, quickUnmount } from "@/lib/useLogin"
import { useModal } from "@/lib/useModal"
import dayjs from "dayjs"
import auth from "@/lib/apiAuth"
import apiDataService from "./lib/apiDataService"
import loadingSpin from "@/components/elements/isLoading.vue"

const { showModal, openModal } = useModal()

const { refreshView, pendingRefresh, qUnmount } = quickUnmount()

watch(pendingRefresh, async (newVal) => {
  if (newVal) {
    await qUnmount()
  }
})

const posPanel = defineAsyncComponent(() => import("@/components/posPanel.vue"))
const headerNav = defineAsyncComponent(() => import("@/components/headerNav.vue"))
const anuncios = defineAsyncComponent(() => import("@/components/elements/anunciosAlerts.vue"))
const Footer = defineAsyncComponent(() => import("@/components/siteFooter.vue"))
const baseModal = defineAsyncComponent(() => import("@/components/elements/baseModal.vue"))
const baseScreenshot = defineAsyncComponent(() => import("@/components/elements/baseScreenshot.vue"))

const router = useRouter()
const lastPromos = useSessionStorage("lastPromos", "")
const lastPromosUser = useSessionStorage("lastPromoUser", "")

const { isLoading, promos_papel, promos_canvas, cartID, cliente } =
  storeToRefs(useCart())
const { Globals, Usr, loadingGlobal, acknowledge } =
  storeToRefs(useCotizadorData())

const logout = async () => {
  try {
    loadingGlobal.value = true
    await auth.logOut()
    console.clear()
    useCart().restore()
    useCotizadorData().restore()
    await homeCall()
    await fullCall()
    loadingGlobal.value = false
  } catch (errors: any) {
    console.log(errors)
    return errors.message
  }
}

const logout_expire = async () => {
  let refresh_token_expiration = getCookie("refresh_token_expiration")
  let access_token_expiration = getCookie("access_token_expiration")
  if (refresh_token_expiration) {
    if (dayjs().isAfter(dayjs(refresh_token_expiration))) {
      logout()
      return
    }
    let timeout = dayjs(refresh_token_expiration).diff(dayjs(), "millisecond")
    await promiseTimeout(timeout)
    logout()
  }
  if (access_token_expiration && !refresh_token_expiration) {
    logout()
  }
}

const getPromos = async () => {
  try {
    const endpoint = []
    let haveClient = false
    if (cliente.value && cliente.value.id) {
      if (
        (dayjs().diff(dayjs(lastPromosUser.value), "minutes") > 1 || !lastPromosUser.value) &&
        !cartID.value
      ) {
        endpoint.push(`data/promos/cliente/${cliente.value.id}/`)
        haveClient = true
      }
    }
    if (dayjs().diff(dayjs(lastPromos.value), "minutes") > 1 || !lastPromos.value) {
      endpoint.push("data/promos/")
    }
    if (endpoint) {
      const resp = await apiDataService.parallelGet(endpoint)
      if (haveClient && !cartID.value) {
        let client_promos = resp[`data/promos/cliente/${cliente.value.id}/`]
        promos_papel.value.Cliente = [...client_promos.Papeles, ...client_promos.porTotal]
        promos_canvas.value.Cliente = client_promos.Canvas
        lastPromosUser.value = dayjs().toString()
      }
      if (resp["data/promos/"]) {
        let promos = resp["data/promos/"]
        promos_papel.value.General = [...promos.Papeles, ...promos.porTotal]
        promos_canvas.value.General = promos.Canvas
        lastPromos.value = dayjs().toString()
      }
    }
  } catch (error) {
    console.log(error)
  }
}

onBeforeMount(async () => {
  Globals.value = await homeCall()
  await getPromos()
  await logout_expire()
})

router.afterEach(async () => {
  await getPromos()
})


const toggleBodyClass = (isOpen: boolean) => {
  if (isOpen) {
    document.body.classList.add('modal-open')
  } else {
    document.body.classList.remove('modal-open')
  }
}

import announcement from "./components/modals/announcement.vue"

onMounted(() => {
  if(acknowledge.value !== 'cerrado_ok') {
    openModal(announcement, true)
  }
})

watch(showModal, (newVal) => {
  toggleBodyClass(newVal)
})

onBeforeUnmount(() => {
  toggleBodyClass(false)
})
</script>