
import { Medidas } from "./commons";
import { ref } from "vue";

const categoria = ref("")
const papel = ref("")

export const setAnalytics = () => {
    return {
        categoria,
        papel
    }
}

export const useAnalytics = () => {

    const sizeChange = (size: basicSize) => {
        const sizetxt = Medidas.sizeTxt(size)
        if (window._paq) {
          window._paq.push(['trackEvent', categoria.value, 'Cambio de tamaño', sizetxt ])
        }
      }

    const PapelSelected = (papelSel: string) => {
        papel.value = papelSel
        if (window._paq) {
            window._paq.push(['trackEvent', categoria.value, 'Sustrato', papel.value])
        }
    }

    const SoporteSelected = (soporteSel: string) => {
        if (window._paq) {
            window._paq.push(['trackEvent', categoria.value, 'Soporte', soporteSel])
        }
    }

    const LaminadoSelected = (laminadoSel: string) => {
        if (window._paq) {
            window._paq.push(['trackEvent', categoria.value, 'Laminado', laminadoSel])
        }
    }

    const paperCard = (action: string) => {
        if (window._paq) {
            window._paq.push(['trackEvent', categoria.value, 'ImageCard', action])
        }
    }

    const botonState = (name: string, action: string) => {
        if (window._paq) {
            window._paq.push(['trackEvent', categoria.value, name, action])
        }
    }

    const margenState = (margen: string) => {
        if (window._paq) {
            window._paq.push(['trackEvent', categoria.value, 'Margen', margen])
        }
    }

    const selectList = (seleccion: any, tipo: string) => {
        if(tipo === "Rollos"){
            PapelSelected(seleccion.Slug)
        }
        if(tipo === "Soportes"){
            SoporteSelected(seleccion.Slug)
        }
        if(tipo === "Laminados"){
            LaminadoSelected(seleccion.Slug)
        }
    }

    const addToCart = (item: string) =>{
        if (window._paq) {
            window._paq.push(['trackEvent', categoria.value, 'Agregado a cotización', item])
        }
    }

    return {
        sizeChange,
        PapelSelected,
        SoporteSelected,
        LaminadoSelected,
        selectList,
        paperCard,
        margenState,
        botonState,
        addToCart
    }

    }
	