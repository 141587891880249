import { createApp } from "vue"
import { createPinia } from "pinia"

import App from "./App.vue"
import router from "@/lib/router"
import "@/style/style.css"
import "@/types/IsCotizador"
import "@/types/isStore"
import FloatingVue from "floating-vue"
import "floating-vue/dist/style.css"
import "viewerjs/dist/viewer.css"
import VueViewer from "v-viewer"
import VueMatomo from "vue-matomo"
import { analyticsConfig } from "@/http-common"

const { url, siteId } = analyticsConfig()

const app = createApp(App)
  .use(router)
  .use(createPinia())
  .use(FloatingVue)
  .use(VueViewer)
  .use(VueMatomo, {
    host: url,
    siteId: siteId,
    router: router,
  })

app.mount("#app")
