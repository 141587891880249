import { Redondeo, Medidas } from "./commons"
import { Canvas as Promo, Papeles as PromoTool } from "./promotions"

type Medida = { ladoMayor: number; ladoMenor: number; minMax?: number }

export class Canvas {
  static MaderasCustom = (Maderas: any) => {
    const material = []
    for (const wood of Maderas) {
      if (wood.Disponible === true) material.push(wood)
    }
    return material
  }
  // antes Margen
  static Rebase = (Bastidor: any) => {
    return Redondeo.round((Bastidor.Grosor * 2) / Bastidor.Unidad + 1.49, 0)
  }

  static MaderasStock = (Bastidores: any) => {
    const Material: string[] = []
    for (const bastidor of Bastidores) {
      if (bastidor.isVisible) {
        if (Material.length === 0) {
          Material.push(bastidor.Material.Nombre)
        }
        if (!Material.find((mas) => mas === bastidor.Material.Nombre)) {
          Material.push(bastidor.Material.Nombre)
        }
      }
    }
    return { Material }
  }

  static filterbyMadera = (Bastidores: any, Madera: string) => {
    const listaBastidores = Bastidores.filter(
      (bast: any) => bast.Material.Nombre === Madera && bast.isVisible,
    )
    return listaBastidores
  }

  static randomBastidor = (listBastidores: { [key: string]: any }) => {
    const lista = Object.values(listBastidores)
    const random = Math.floor(Math.random() * lista.length)
    return lista[random]
  }

  static findCanto = (Cantos: any, Size: any, Madera: string) => {
    const search = Cantos.filter((item: any) => Madera === item.Material.Slug)
    if (!search) return Cantos[0].Canto
    return search[Size].Cantos[0]
  }

  // Acomoda los bastidores por tamaño y agrupa el stock
  static pzDisponibles = (filteredBastidores: any) => {
    const Value = filteredBastidores.sort((a: any, b: any) => {
      const alpha =
        (a.Medida.Ancho + a.Medida.Largo) / a.Medida.Unidad + a.Canto.Grosor / a.Canto.Unidad
      const beta =
        (b.Medida.Ancho + b.Medida.Largo) / b.Medida.Unidad + b.Canto.Grosor / b.Canto.Unidad
      if (alpha > beta) return 1
      return -1
    })
    const list = Value.reduce((bastidor: any, detail: any) => {
      const unit = Medidas.txtUnit(detail.Medida.Unidad)
      const size = `${detail.Medida.Ancho}x${detail.Medida.Largo}${unit}`
      const canto = detail
      if (bastidor[size] === undefined) {
        // eslint-disable-next-line no-param-reassign
        bastidor[size] = {
          Size: size,
          Medidas: detail.Medida,
          Bastidores: [],
          showNames: [],
        }
      }
      bastidor[size].Bastidores.push(canto)
      return bastidor
    }, {})
    return list
  }

  static sizeFinal = (Store: any) => {
    const mayor = Math.max(Store.Medidas.Ancho, Store.Medidas.Largo)
    const menor = Math.min(Store.Medidas.Ancho, Store.Medidas.Largo)
    const Bastidor = Store.Bastidor ? Store.Bastidor : Store.Canto
    const Rebase = this.Rebase(Bastidor)
    const Medida = Store.Medidas
    const Inch: Medida = {
      ladoMayor: Redondeo.round(mayor / Store.Medidas.Unidad, 2),
      ladoMenor: Redondeo.round(menor / Store.Medidas.Unidad, 2),
    }
    const finalInch = {
      ladoMayor: Redondeo.round(Inch.ladoMayor + Rebase, 2),
      ladoMenor: Redondeo.round(Inch.ladoMenor + Rebase, 2),
    }
    const bastidore = Bastidor.GrosorBast * Bastidor.Unidad
    const minMax = Store.Rollo.precios[0].size

    return { Medida, Inch, finalInch, minMax, bastidore }
  }

  //Requiere un objeto con la Listas de Cantos, Maderas y Barnices
  static cost = (Store: any, MaderaPrice: number) => {
    const bastidor = Store.Canto // VEr esta WEA
    const mayor = Math.max(Store.Medidas.Ancho, Store.Medidas.Largo)
    const menor = Math.min(Store.Medidas.Ancho, Store.Medidas.Largo)
    let Medida = {
      ladoMayor: Redondeo.round(mayor / Store.Medidas.Unidad + this.Rebase(bastidor), 2),
      ladoMenor: Redondeo.round(menor / Store.Medidas.Unidad + this.Rebase(bastidor), 2),
    }
    if (mayor <= 0 || menor <= 0)
      Medida = {
        ladoMayor: 0,
        ladoMenor: 0,
      }
    const Area = Medidas.Area(Medida)
    const Perimetro = Redondeo.round(
      (Store.Medidas.Ancho / Store.Medidas.Unidad + Store.Medidas.Largo / Store.Medidas.Unidad) * 2,
      2,
    )
    const Barniz = Store.Barniz
    const costBastidor = Redondeo.round(Perimetro * MaderaPrice, 2)
    const costBarniz = Redondeo.round(Area * Barniz.PrecioIn2, 2)
    const costImpresion = Redondeo.round(Area * Store.Rollo.precios[0].Precio, 1)
    const costBastidorBack = Redondeo.round(costBastidor - costBastidor * 0.2, 1)
    const costBarnizBack = Redondeo.round(costBarniz - costBarniz * 0.2, 1)
    let Costo = Redondeo.round(costBarniz + costBastidor + costImpresion, 1)
    if (Store.Opciones.Bastidor === false) Costo = Redondeo.round(Costo - costBastidorBack, 1)
    if (Store.Opciones.Barniz === false) Costo = Redondeo.round(Costo - costBarnizBack, 1)

    return { Costo, costBastidorBack, costBarnizBack, Store }
  }

  static DefineMeta = (Store: any) => {
    const MetaData: any = {
      Rollo: {
        Slug: Store.Rollo.Slug,
        Marca: Store.Rollo.Marca.Slug,
        Linea: Store.Rollo.Linea.Categoria.Slug,
      },
      Medidas: {
        Ancho: Store.Medidas.Ancho,
        Largo: Store.Medidas.Largo,
        Unidad: Store.Medidas.Unidad,
      },
      Canto: Store.Canto.Slug,
      Barniz: Store.Barniz.Slug,
      Madera: Store.Madera,
      AcabadoCanto: Store.AcabadoCanto.Nombre,
      Stock: false,
    }
    if (Store.Bastidor.Medida) {
      MetaData.Stock = true
    }
    return MetaData
  }

  static Descriptor = (Store: any) => {
    const descripcion = {
      Marca: Store.Rollo.Marca.Marca,
      Name: Store.Rollo.Nombre,
      Gramaje: Store.Rollo.Gramaje + "gr/m²",
      Size: Store.Medidas.Ancho + "x" + Store.Medidas.Largo + Medidas.txtUnit(Store.Medidas.Unidad),
      Canto: Store.AcabadoCanto.Nombre,
      Bastidor: Store.Canto.Slug,
      Barniz: Store.Barniz.Acabado,
      Madera: Store.Madera,
      Piezo: Store.Rollo.Piezogaphy,
    }
    return descripcion
  }

  static Item = (Store: any, Cost: any, promotions: any) => {
    const Seleccion = this.Descriptor(Store)
    const MetaData = this.DefineMeta(Store)
    const Descripcion = `Canto de ${Seleccion.Bastidor} · ${Seleccion.Canto} · ${Seleccion.Barniz} · ${Seleccion.Madera}`
    const Piezas = Store.Piezas
    const BarnizDesc = `protegido con barniz Uv ${Seleccion.Barniz.toLowerCase()}`
    const Bastidor = `montado sobre bastidor de ${Seleccion.Madera.toLowerCase()} con ${Seleccion.Bastidor.toLowerCase()} de grosor`
    const acabCanto = `con canto ${Seleccion.Canto.toLowerCase()}`
    const DescripcionTexto = `Lienzo ${Seleccion.Size} impreso sobre ${Seleccion.Marca} ${Seleccion.Name} de ${Seleccion.Gramaje}, ${Bastidor} ${BarnizDesc} ${acabCanto}`
    const Desglose = {
      Bastidor: Cost.costBastidorBack,
      Barniz: Cost.costBarnizBack,
      Impresion: Redondeo.round(Cost.Costo - Cost.costBastidorBack - Cost.costBarnizBack, 1),
    }
    const applypromo = Promo.applyDiscounts(Cost.Costo, Store, promotions)
    const final_costos = {
      Base: {
        ...Desglose,
      },
      Descuentos: {
        total_del_descuento: applypromo.discount,
        percent: applypromo.promo.percent,
        cantidad_descuento: {
          Bastidor: Redondeo.round(Desglose.Bastidor * (applypromo.promo.percent / 100), 1),
          Barniz: Redondeo.round(Desglose.Barniz * (applypromo.promo.percent / 100), 1),
          Impresion: Redondeo.round(Desglose.Impresion * (applypromo.promo.percent / 100), 1),
        },
      },
      Finales: {
        Bastidor: 0,
        Barniz: 0,
        Impresion: 0,
      },
      basePorPieza: 0,
      totalPorPieza: 0,
      piezas: Piezas,
      subTotal: 0,
      acumulable: true,
    }

    final_costos.Finales.Bastidor = Redondeo.round(
      Desglose.Bastidor - final_costos.Descuentos.cantidad_descuento.Bastidor,
      1,
    )
    final_costos.Finales.Barniz = Redondeo.round(
      Desglose.Barniz - final_costos.Descuentos.cantidad_descuento.Barniz,
      1,
    )
    final_costos.Finales.Impresion = Redondeo.round(
      Desglose.Impresion - final_costos.Descuentos.cantidad_descuento.Impresion,
      1,
    )
    final_costos.totalPorPieza = Redondeo.round(PromoTool.sumInternal(final_costos.Finales), 1)
    final_costos.subTotal = Redondeo.round(final_costos.totalPorPieza * Piezas, 1)
    final_costos.basePorPieza = Redondeo.round(PromoTool.sumInternal(final_costos.Base), 1)
    final_costos.acumulable = applypromo.acumulable

    return {
      final_costos,
      Seleccion,
      Descripcion,
      DescripcionTexto,
      MetaData,
    }
  }

  static setStockDefault = (defaults: defaultCanvasResponse) => {
    const Selection = {
      Medidas: defaults.Medida,
      Rollo: defaults.Rollo,
      Piezas: 1,
      Madera: defaults.Material.Nombre,
      Canto: defaults.Canto,
      Barniz: defaults.Barniz,
      Bastidor: {} as Bastidor,
      Size: "",
      oldSize: "",
      AcabadoCanto: defaults.AcabadoCanto,
      Opciones: { Bastidor: true, Barniz: true },
    }
    return Selection
  }

  static setCustomDefault = (defaults: defaultCanvasResponse) => {
    const Selection = {
      Medidas: defaults.Medida,
      Rollo: defaults.Rollo,
      Piezas: 1,
      Madera: defaults.Material.Nombre,
      Canto: defaults.Canto,
      Bastidor: defaults.Canto,
      Barniz: defaults.Barniz,
      AcabadoCanto: defaults.AcabadoCanto,
      Size: "",
      Opciones: { Bastidor: true, Barniz: true },
    }
    return Selection
  }
}
