<template>
  <div class="flex w-full justify-center py-32">

      <div class="spring-spinner">
        <div class="spring-spinner-part top">
          <div class="spring-spinner-rotator"></div>
            </div>
        <div class="spring-spinner-part bottom">
          <div class="spring-spinner-rotator"></div>
        </div>
        <span class="hidden">espere un momento</span>
      </div>
     

  </div>
</template>

<style lang="scss" scoped>
.spring-spinner, .spring-spinner * {
      box-sizing: border-box;
    }

    .spring-spinner {
      height: 160px;
      width: 160px;
    }

    .spring-spinner .spring-spinner-part {
      overflow: hidden;
      height: calc(160px / 2);
      width: 160px;
    }

    .spring-spinner  .spring-spinner-part.bottom {
       transform: rotate(180deg) scale(-1, 1);
     }

    .spring-spinner .spring-spinner-rotator {
      width: 160px;
      height: 160px;
      border: calc(160px / 7) solid transparent;
      border-right-color: #6e6e6e;
      border-top-color: #4d4d4d;
      border-radius: 50%;
      box-sizing: border-box;
      animation: spring-spinner-animation 3s ease-in-out infinite;
      transform: rotate(-200deg);
    }

    @keyframes spring-spinner-animation {
      0% {
        border-width: calc(160px / 7);
      }
      25% {
        border-width: calc(160px / 23.33);
      }
      50% {
        transform: rotate(115deg);
        border-width: calc(160px / 7);
      }
      75% {
        border-width: calc(160px / 23.33);
      }
      100% {
        border-width: calc(160px / 7);
      }
    }
  </style>
