<template>
  <div class="p-1.5 bg-gradient-to-br from-cyan-600 from-20% via-yellow-600 via-40% to-red-600 rounded-md relative">
    <div class="rounded-md bg-neutral-100 dark:bg-neutral-800 dark:bg-opacity-90 bg-opacity-90 px-2 md:px-9 pt-4 pb-6 text-center">
      <closeIcon class="absolute top-4 right-8 w-8 h-8 hover:scale-110 cursor-pointer text-neutral-600 dark:text-gray-100 opacity-70" @click="closeModal(), botonState('anuncio', 'modal cerrado')" />

  <div class="flex flex-col place-items-center dark:text-neutral-200 mt-5 mb-5">
    <span class="text-center text-2xl mobile:text-4xl tracking-wider">Servicio de impresión</span>
    <span class="text-center text-lg mobile:text-2xl font-semibold">Temporalmente no disponible</span>
  </div>

  <div class="flex flex-col text-sm mobile:text-base bg-neutral-100 bg-opacity-80 p-5 rounded-lg max-w-xl mx-auto max-h-[500px] overflow-y-scroll">
    <span class="font-semibold text-lg text-gray-800 mobile:mb-4 mb-2 text-left">Estimados clientes:</span>

    <p class="text-gray-700 mb-2">
      Nuestro servicio de impresión Fine Art no está disponible en este momento debido a una actualización de nuestros equipos</p>

    <p class="text-gray-700 mb-2">
      Estamos en proceso de instalar nuevos equipos de última generación, esta mejora nos permitirá ofrecerles un servicio de calidad muy pronto. 
    </p>

    <p class="text-gray-700 mb-2">
     Aunque temporalmente no podemos procesar órdenes de impresión, nuestra herramienta de cotización en línea sigue funcionando con normalidad. 
    </p>

    <p class=" text-gray-700 mb-2">
      Te invitamos a seguir usandolá para planificar futuros proyectos.
    </p>

    <p class="text-gray-700 mb-2">
      Estamos trabajando para reanudar operaciones lo antes posible, les mantendremos informados sobre la fecha exacta de reapertura.
    </p>

    <p class="text-gray-700 mb-2">
      Agradecemos enormemente su comprensión y paciencia durante este breve proceso de mejora. Su continuo apoyo y confianza en nuestro trabajo son fundamentales para nosotros.
    </p>

    <p class="text-center text-gray-900 font-bold tracking-tight mt-3">
      ¡Muchas gracias!
    </p>

  </div>

    <div class="btn-base mt-3 mx-4 text-lg mobile:text-2xl" @click="close()">
      Acceder al cotizador
    </div>

</div>
</div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useCotizadorData } from "@/store/CotizadorStore";
import closeIcon from "@/assets/icons/iconClose.svg?component"

const { acknowledge } =
  storeToRefs(useCotizadorData())

import { useAnalytics } from "@/lib/useAnalytics"
const { botonState } = useAnalytics()

import { useModal } from "@/lib/useModal"
const { closeModal } = useModal()

const close = () => {
  acknowledge.value = 'cerrado_ok'
  botonState("anuncio", "entendido")
  closeModal()
}

</script>
